<template>
  <div class="fill-height">
    <v-container class="pb-15 mb-15">
      <v-card class="mb-4 mx-auto mt-5" max-width="800">
        <v-skeleton-loader :loading="news.is_loading" type="article">
          <div>
            <div v-if="!error">
              <v-img
                v-if="news.cover"
                :src="news.cover.width_1024"
                max-height="500"
              ></v-img>

              <v-card-title>
                <h1 class="headline news-title">
                  {{ news.title | capitalize }}
                </h1>
              </v-card-title>

              <v-card-text
                v-html="news.content"
                class="text--primary subtitle-1"
              ></v-card-text>
              <v-card-actions v-if="is_admin">
                <v-btn text color="primary" :to="'/news/edit/' + news.id">{{
                  $t("Edit")
                }}</v-btn>
              </v-card-actions>
            </div>
            <error-page :error="error" v-if="error"></error-page>
          </div>
        </v-skeleton-loader>
      </v-card>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
    "Edit": "Edit"
    },
    "sv": {
     "Edit": "Redigera"
    }
    }
</i18n>

<script>
// @ is an alias to /src
import { newsService } from "../../../_services";

export default {
  name: "news_item",
  components: {},
  metaInfo() {
    return {
      title: this.meta.title,
      meta: this.metas,
    };
  },
  data: () => ({
    news: {},
    error: null,
    is_loading: false,
    meta: {
      title: ""
    },
    metas: [],
  }),
  created: function () {
    this.is_admin = this.$store.getters["account/isInRole"]("Admin");

    this.load();
  },
  methods: {
    load() {
      if (this.$route.params.name) {
        this.loadItem(this.$route.params.name);
      } else {
        this.$router.push("/news");
        return;
      }
    },
    loadItem(name) {
      var self = this;
      self.is_loading = true;
      self.error = null;

      newsService
        .getNewsItem(name)
        .then(function (response) {
          self.is_loading = false;

          self.news = response.data;
          self.setMeta();

          self.meta.title = response.data.title;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    setMeta() {
      this.metas = [];

      this.metas.push({
        property: "og:title",
        content: this.news.title + " | " + process.env.VUE_APP_NAME,
        vmid: "og:title",
      });
      this.metas.push({
        property: "og:description",
        content: this.news.summery,
        vmid: "og:description",
      });
      this.metas.push({
        name: "description",
        content: this.news.summery,
        vmid: "description",
      });

      if (this.news.cover) {
        this.metas.push({
          property: "og:image",
          content: this.news.cover.width_1024,
          vmid: "og:image",
        });
      }
    },
  },
  watch: {
    $route() {
      this.load();
    },
  },
};
</script>

<style scoped>
.news-title a {
  text-decoration: none;
  color: black !important;
}
.news-title a:hover {
  text-decoration: underline;
}
</style>