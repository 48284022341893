<template>
  <div class="fill-height">
    <v-container class="pb-15 mb-15">
      <h1 class="font-weight-light main-h2 mt-5 mb-5">
        {{ $t("News") }}
        <v-btn
          color="primary"
          fab
          small
          top
          right
          to="/news/manage"
          v-if="is_admin"
        >
          <v-icon small>$vuetify.icons.farEdit</v-icon>
        </v-btn>
      </h1>
      <v-skeleton-loader :loading="is_loading" type="article">
        <div>
          <v-row class="blog-post-cards mobile-margin-fix mt-0">
            <v-col
              v-for="item in news"
              :key="item.id"
              cols="12"
              xs="12"
              md="4"
              class="pt-0"
            >
              <v-card class="mb-3" flat>
                <v-card :to="getUrl(item)">
                  <v-img
                    v-if="item.cover"
                    :src="item.cover.width_400"
                    height="200px"
                  ></v-img>

                  <v-card-title class="news-title">
                    <h2 class="headline">
                      {{ item.title | capitalize }}
                    </h2>
                  </v-card-title>

                  <v-card-subtitle class="caption">
                    {{ $t("Published") }} {{ getDate(item.publish_date) }}
                  </v-card-subtitle>
                  <v-card-text class="text--primary">
                    <div class="news-preamble">{{ item.summery }}</div>
                  </v-card-text>
                  <v-card-actions class="pr-4 pl-4 pt-3 pb-3">
                    <v-icon small class="tag-icon">$vuetify.icons.fasTags</v-icon>
                    <router-link
                      class="tag"
                      :to="'/news/category/' + item.category.url_name"
                      >{{ item.category.name | capitalize }}</router-link
                    >
                    <v-spacer></v-spacer>
                    <router-link v-if="is_admin" :to="'/news/edit/' + item.id"
                      >Edit</router-link
                    >
                  </v-card-actions>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              xs="12"
              cols="12"
              v-if="unit_pages > 1"
              class="text-center mt-10"
            >
              <v-pagination
                v-model="page"
                :length="unit_pages"
                :total-visible="7"
              ></v-pagination>
            </v-col>

            <v-col
              xs="12"
              cols="12"
              v-if="!news || news < 1"
              class="text-center"
            >
              {{ $t("NoNews") }}
            </v-col>
          </v-row>
          <error-page
            :error="news.error"
            v-if="news.error"
            home="/"
          ></error-page>
        </div>
      </v-skeleton-loader>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
    "News": "News",
    "Home": "Home",
    "Categories": "Categories",
    "Administrate": "Administrate",
    "NoNews": "There is no written news to show!",
    "Manage": "Manage news",
    "CreateNews": "Create news",
    "Edit": "Edit",
    "MetaDescription": "Read the latest new from Babiry!",
    "Published": "Published"
    },
    "sv": {
    "News": "Nyheter",
    "Home": "Hem",
    "Categories": "Kategorier",
    "Administrate": "Administrate",
    "NoNews": "Det finns inga publicerade nyheter att visa ännu!",
    "Manage": "Hantera nyhater",
    "CreateNews": "Skriv nyhet",
    "Edit": "Redigera",
    "MetaDescription": "Läs de senaste nyheterna från Barndagboken!",
    "Published": "Publicerad"
    }
    }
</i18n>

<script>
import { newsService } from "../../../_services";
import { dateHelpers } from "@/_helpers";

export default {
  name: "news_cards",

  data: () => ({
    news: [],

    category_name: null,
    page: 1,
    unit_items: 0,
    unit_pages: 0,

    is_admin: false,
    is_loading: false,
    error: null,

    meta: {
      title: "",
      description: "",
    },
  }),
  metaInfo() {
    return {
      title: this.meta.title,
      meta: [
        {
          property: "og:title",
          content: this.meta.title + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.meta.description,
          vmid: "og:description",
        },
        {
          name: "description",
          content: this.meta.description,
          vmid: "description",
        },
      ],
    };
  },

  components: {},
  created: function () {
    this.is_admin = this.$store.getters["account/isInRole"]("Admin");

    this.meta.title = this.$t("News");
    this.meta.description = this.$t("MetaDescription");
    this.load();
  },
  methods: {
    load() {
      this.setFromUrl();
      this.loadNews();
    },
    loadNews() {
      var self = this;
      self.is_loading = true;
      self.error = null;

      var req;

      if (self.category_name && self.category_name != "") {
        req = newsService.newsInCategory(self.category_name, self.page);
      } else {
        req = newsService.newsCards(self.page, 15);
      }

      req
        .then(function (response) {
          self.is_loading = false;
          self.news = response.data.data;
          self.unit_pages = response.data.unit_pages;
          self.unit_items = response.data.unit_items;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },

    getUrl(item) {
      return "/news/" + item.url_name;
    },
    getDate(date) {
      var dt = dateHelpers.getFormatedDateFromDatetime(date);
      return dateHelpers.getFormatedTimelineDate(dt);
    },

    setFromUrl() {
      this.category_name = null;
      this.page = 1;

      if (this.$route.params.category)
        this.category_name = this.$route.params.category;

      if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    },
  },
  watch: {
    $route() {
      this.load();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.page) return;
      self.$router.push({ query: { page: self.page } });

      self.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped>
.blog-post-cards .news-title h2 {
  padding-right: 40px !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 20px !important;
  line-height: 27px !important;
}
.news-preamble {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-post-cards .tag-icon {
  margin-right: 7px;
}
.blog-post-cards a.tag {
  color: gray;
  font-size: 14px;
}
</style>